import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish a max of:`}</p>
    <p>{`1-High (pocket) Hang Squat Snatch +\\
1-Low (just below knee) Hang Squat Snatch +\\
1-Full Snatch`}</p>
    <p><em parentName="p">{`*`}{`compare to 1/13/21`}</em></p>
    <p>{`then, 3rds for time of:`}</p>
    <p>{`12-Power Snatch`}</p>
    <p>{`10-OHS`}</p>
    <p>{`8-Hang Power Snatch`}</p>
    <p>{`(95/65) (RX+ 115/75)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our Free Partner WOD so bring a friend! If you
aren’t a member but would like to attend email:
Daniel\\@crossfittheville.org to reserve your spot. Classes are at 9:00 &
10:15am.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      